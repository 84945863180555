import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Masonry from '@mui/lab/Masonry';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { ArrowRight } from '@mui/icons-material';
import { dessertImages } from '../../components/constants';

export const Toys = () => {

  return (
    <Grid
      container
      justifyContent='center'
      // sx={{
      //   height: 'calc(100vh)'

    >
      <Grid item xs={11}>
        <Typography
          variant='h1'
          sx={{ textAlign: 'center', color: 'white' }}
        >
          Our Toys
        </Typography>
        <ResponsiveColumns />
      </Grid>
    </Grid>
  );
};

export default function ResponsiveColumns() {
  return (
    <Box sx={{ width: '100%' }}>
      <Masonry columns={{ xs: 1, sm: 3 }} spacing={2}>
        {[...dessertImages, ...dessertImages].map((i) => (
          <ImageListItem key={i.id}>
            <img
              src={i.image}
              // srcSet={`${i.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
              alt={i.title}
              loading='lazy'
            />
            <ImageListItemBar
              title={i.title}
              position='top'
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
              }}
              actionIcon={
                <IconButton
                  component={Link}
                  sx={{ color: 'white' }}
                  aria-label={`info about ${i.title}`}
                  href={`/toys/${i.path}`}
                >
                  <ArrowRight sx={{ width: '60px', height: '60px' }} />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </Masonry>
    </Box>
  );
}

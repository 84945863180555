import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { colors } from '../../theme';

const titleContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  margin: '100px 0px',
  '& .MuiBox-root': {
    // display: 'flex',
    // flexDirection: { xs: 'column', md: 'row' },
    // justifyContent: 'space-between',
    // alignItems: 'center',
    width: '100%',
    'h4': {
      // backgroundImage: 'linear-gradient(white, pink)',
      // backgroundSize: '0 100%',
      // backgroundRepeat: 'no-repeat',
      // transition: '0.2s',
      // color: 'white',
      // width: 'fit-content',
      fontSize: {
        xs: '45px',
        sm: '110px'
      }
    },
    'h6': {
      marginTop: '30px',
      // color: 'white',
      textAlign: 'center'
    }
  }
};

const imageContainer = {
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  margin: {
    xs: '0px 0px',
    sm: '100px 0px'
  },
  // '& .MuiBox-root': {
  //   overflowX: 'scroll',
  //   overflowY: 'hidden',
  //   height: '100%',
  //   display: 'flex',
  //   flexDirection: 'row',
  //   scrollbarColor: 'gray transparent'
  // }
};

export const HomeSection = ({ id, children, data, colorTitle, colorButton, viewAllHref }) => {

  return (
    <>
      <Grid id={id} item xs={11} sx={titleContainerSx}>
        <Box>
          <Box>
            {/* <Typography variant='h1' color={colorTitle} */}
            {/*  sx={{ */}
            {/*    transform: 'translateX(0vw) translateZ(0px)', */}
            {/*    color: '#E8D1E0', */}
            {/*    fontSize: { xs: '65px', md:'110px' } */}
            {/*  }} */}
            {/* >{data.title}</Typography> */}
            <Typography variant='h4' color={colorTitle}
            sx={{
                transform: 'translateX(0vw) translateZ(0px)',
                position: 'relative',
              color: colors.pink,
              marginTop: {
                  xs:'-50px', md: '-70px' }
              }}
            >{data.title}
              <Box sx={{
                '&.MuiBox-root': {
                  borderBottom: {
                    xs: '7px solid',
                    md: '10px solid'
                  },
                  color: colors.pink,
                  width: {
                    xs:'80px',
                    md:'160px',
                  },
                  borderRadius: '50px'
                }
                 }} />
            </Typography>
          </Box>
          {data.subtitle ? (
            <Box>
              <Typography variant='subtitle1' color={colorTitle}>{data.subtitle}</Typography>
            </Box>) : null}
        </Box>
      </Grid>
      {children ? (
        <Grid item xs={11} md={10} lg={11} sx={imageContainer}>
          {children}
        </Grid>) : null}
      {viewAllHref ? (
        <Grid
          item
          xs={11}
          margin='100px 0px'
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Button
            component={Link}
            endIcon={<ArrowRightAltIcon />}
            href={viewAllHref}
            size='large'
            sx={{
              '&.MuiTypography-root': {
                fontSize: '40px'
              },
              '&.MuiButton-root': {
                color: colorButton,
                '& .MuiSvgIcon-root': {
                  fontSize: '60px'
                }
              }
            }}
          >
            View all
          </Button>
        </Grid>) : null}
    </>
  );
};
export const colors = {
  pink: '#D761BA',
  pink_75: 'rgba(229, 69, 146, 0.75)',
  green_100: 'rgba(175, 246, 141, 1)',
  yellow_100: 'rgba(251, 233, 169, 1)',
  chocolate: 'rgb(139,69,19)',
  gray: '#5a5a5a',
  g: 'linear-gradient(115deg, #ef629a, #eecda1)',
  g1: 'linear-gradient(115deg, #f4d7d7, #fe8584, #f5d3b3)',
  g2: 'linear-gradient(115deg, #e05a7b, #e0accb)',
  g3: 'rgba(255, 150, 162, 1)',
  g4: 'linear-gradient(#c74f80, rgba(255, 150, 162, 0));',
};
import Toy1 from './assets/toys/toy1.jpeg'
import Toy2 from './assets/toys/toy2.jpeg'
import Toy3 from './assets/toys/toy3.jpeg'
import Toy4 from './assets/toys/toy4.jpeg'
import Toy5 from './assets/toys/toy5.jpeg'
import Toy6 from './assets/toys/toy6.jpeg'
import Toy7 from './assets/toys/toy7.jpeg'
import Toy8 from './assets/toys/toy8.jpeg'
import Toy9 from './assets/toys/toy9.jpeg'
import Toy10 from './assets/toys/toy10.jpeg'
import Toy11 from './assets/toys/toy11.jpeg'
import Toy12 from './assets/toys/toy12.jpeg'
import Team2 from './assets/team/IMG-8960.JPG'
import Team3 from './assets/team/IMG-8962.JPG'
import Team4 from './assets/team/IMG-8963.JPG'
import Team5 from './assets/team/IMG-9149.JPG'
import Team6 from './assets/team/DFB9A615-AE0C-476C-8FEC-819B5609960D_1_105_c.jpeg'
import Team7 from './assets/team/3A9DF088-8DE9-4172-947D-D68F5938DFDC.jpeg'
import {
  Sundae,
  Sundae1,
  Sundae2,
  Crepe,
  Crepe2,
  Crepe4,
  Pancake,
  Pancake1,
  Milkshake,
  Milkshake1,
  Milkshake2,
  Milkshake3,
  Milkshake4,
  Milkshake5,
  BubbleWaffle,
  BubbleWaffle1,
  BubbleWaffle3,
  BubbleWaffle4,
  Brownie,
  Brownie2,
  BelgianWaffle1,
  BelgianWaffle2,
  Smoothie,
  StickyToffeePudding,
  StickyToffeePudding1,
} from './assets/ourMenu/index'

export const navItems = [
  { label: 'Home', id: 'app', isActive: true },
  { label: 'Our Menu', id: 'our-menu', isActive: false },
  { label: 'About Us', id: 'about-us', isActive: false },
  { label: 'Contact', id: 'footer', isActive: false }
];

export const dessertByUrlPath = {
  sundae: { path: 'sundae', title: 'Sundaes' },
  pancake: { path: 'pancake', title: 'Pancakes' },
  crepe: { path: 'crepe', title: 'Crepes' },
  milkshake: { path: 'milkshake', title: 'Milkshakes' },
  smoothie: { path: 'smoothie', title: 'Smoothies' },
  belgianWaffle: { path: 'belgian-waffle', title: 'Belgian Waffles' },
  bubbleWaffle: { path: 'bubble-waffle', title: 'Bubble Waffles' },
  hotBrownie: { path: 'hot-brownie', title: 'Hot Brownies' },
  hotDrink: { path: 'hot-drink', title: 'Hot Drinks' }
};

export const mainSectionImages = [
  
  {
    id: '2',
    src: 'MainBiscoffSundae',
    alt: 'a'
  },
  {
    id: '3',
    src: 'MainSundae',
    alt: 'a'
  },
  {
    id: '4',
    src: 'MainCops',
    alt: 'a'
  },
  {
    id: '5',
    src: 'MainCrepe',
    alt: 'a'
  },
  {
    id: '6',
    src: 'MainPancake',
    alt: 'a'
  },
  {
    id: '7',
    src: 'MainPancakes',
    alt: 'a'
  },
  {
    id: '8',
    src: 'MainBubbleWaffle',
    alt: 'a'
  },
  {
    id: '9',
    src: 'MainHappyFlowers',
    alt: 'a'
  },
  {
    id: '10',
    src: 'MainIceCream',
    alt: 'a'
  },
  {
    id: '11',
    src: 'MainMilkshake',
    alt: 'a'
  },
  {
    id: '12',
    src: 'MainSundaeBueno',
    alt: 'a'
  },
  {
    id: '13',
    src: 'MainMixDessert',
    alt: 'a'
  },
  {
    id: '14',
    src: 'MainMixDessert2',
    alt: 'a'
  },
  {
    id: '15',
    src: 'MainPancake2',
    alt: 'a'
  },
  {
    id: '16',
    src: 'MainSundae2',
    alt: 'a'
  },
  {
    id: '17',
    src: 'MainSundae4',
    alt: 'a'
  },
]
export const dessertImages = [
  {
    id: 1,
    path: dessertByUrlPath.sundae.path,
    title: 'SUNDAES',
    subtitle: '5',
    image: Sundae,
    images: [
      {id: '1', src: Sundae1, alt: 'Sundae'},
      {id: '2', src: Sundae, alt: 'Sundae'},
      {id: '3', src: Sundae2, alt: 'Sundae'},
    ],
    variants: ['Oreo', 'Milky Way', 'Kinder Bueno', 'Aero Mint', 'Smarties', 'Lotus Biscoff', 'Hershey', 'Reese\u0027s'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2Fsundae.mp4?alt=media&token=78af498a-0c5d-446c-b968-d1dfa2a6a11a',
    alt: '',
    cardHeight: {
      xs: '720px',
      sm: '820px'
    }
  },
  {
    id: 2,
    path: dessertByUrlPath.pancake.path,
    title: 'PANCAKES',
    subtitle: '14',
    variants: ['Nutella', 'Maplesyrup', 'Lemon \u0026 Sugar', 'Nutella \u0026 Strawberry'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2Fpancake.mp4?alt=media&token=5cdb2ddb-2d65-451a-b43d-dbfc08a54200',
    image: Pancake1,
    images: [
      {id: '1', src: Pancake, alt: 'PANCAKE'},
      {id: '2', src: Pancake1, alt: 'PANCAKE'},
    ],
    alt: '',
    cardHeight: {
      xs: '680px',
      sm: '815px',
    }
  },
  {
    id: 3,
    path: dessertByUrlPath.crepe.path,
    title: 'CREPES',
    subtitle: '10',
    variants: ['Nutella', 'Kinder Bueno', 'Oreo', 'Nutella \u0026 Strawberry', 'Lotus Biscoff', 'Milky Way', 'Aero Mint', 'Reese\u0027s'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2Fcrepe.mp4?alt=media&token=3f28f52d-464a-4203-96f9-212d3de14aa0',
    image: Crepe,
    images: [
      {id: '1', src: Crepe, alt: 'CREPE'},
      {id: '2', src: Crepe2, alt: 'CREPE'},
      {id: '4', src: Crepe4, alt: 'CREPE'},
    ],
    alt: '',
    cardHeight: {
      xs: '725px',
      sm: '865px'
    }
  },
  {
    id: 4,
    path: dessertByUrlPath.milkshake.path,
    title: 'MILKSHAKES',
    subtitle: '8',
    variants: ['Vanilla', 'Kinder Bueno', 'Oreo', 'Chocolate', 'Malteser', 'Hershey', 'Milky Bar', 'M\u0026M\u0027s', 'Reese\u0027s', 'Bubblegum', 'Mint Chocolate', 'Brownie'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2Fmilkshake.mp4?alt=media&token=040094d5-722c-44ff-a917-204df857afcd',
    image: Milkshake,
    images: [
      {id: '1', src: Milkshake, alt: 'MILKSHAKE'},
      {id: '2', src: Milkshake1, alt: 'MILKSHAKE'},
      {id: '3', src: Milkshake2, alt: 'MILKSHAKE'},
      {id: '4', src: Milkshake3, alt: 'MILKSHAKE'},
      {id: '5', src: Milkshake4, alt: 'MILKSHAKE'},
      {id: '6', src: Milkshake5, alt: 'MILKSHAKE'},
    ],
    alt: '',
    cardHeight: {
      xs: '765px',
      sm: '865px'
    }
  },
  {
    id: 5,
    path: dessertByUrlPath.smoothie.path,
    title: 'SMOOTHIES',
    subtitle: '5',
    variants: ['Strawberry \u0026 Banana', 'Tropical', 'Mixed Berry', 'Hershey\u0027s Protein'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2Fsmoothie.mp4?alt=media&token=42801966-0b3b-4ac5-92b3-5a1ced454160',
    image: Smoothie,
    images: [
      {id: '1', src: Smoothie, alt: 'Smoothie'},
      {id: '2', src: Smoothie, alt: 'Smoothie'},

    ],
    alt: '',
    cardHeight: {
      xs: '685px',
      sm: '820px'
    }
  },
  {
    id: 6,
    path: dessertByUrlPath.belgianWaffle.path,
    title: 'BELGIAN',
    title2: 'WAFFLES',
    subtitle: '6',
    variants: ['Kinder Bueno', 'Oreo', 'Lotus Biscoff', 'Milky Way', 'Aero Mint', 'Reese\u0027s', 'Very Berry', 'Ferrero Rocher'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2FbelgianWaffle.mp4?alt=media&token=140fd86c-39f5-42de-b271-9b1c2e5bced0',
    image: BelgianWaffle1,
    images: [
      { id: '1', src: BelgianWaffle1, alt: 'Belgian Waffle' },
      { id: '2', src: BelgianWaffle2, alt: 'Belgian Waffle' },
    ],
    alt: '',
    cardHeight: {
      xs: '770px',
      sm: '900px'
    }
  },
  {
    id: 8,
    path: dessertByUrlPath.bubbleWaffle.path,
    title: 'BUBBLE',
    title2: 'WAFFLES',
    subtitle: '15',
    variants: ['Kinder Bueno', 'Oreo', 'Lotus Biscoff', 'Milky Way', 'Aero Mint', 'Reese\u0027s', 'Very Berry', 'Ferrero Rocher'],
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2FbubleWaffle.mp4?alt=media&token=ab73ea06-fe43-4585-bd29-43c87347c8a4',
    image: BubbleWaffle,
    images: [
      {id: '1', src: BubbleWaffle, alt: 'Bubble Waffle'},
      {id: '2', src: BubbleWaffle1, alt: 'Bubble Waffle'},
      {id: '3', src: BubbleWaffle3, alt: 'Bubble Waffle'},
      {id: '4', src: BubbleWaffle4, alt: 'Bubble Waffle'},
    ],
    alt: '',
    cardHeight: {
      xs: '770px',
      sm: '900px'
    }
  },
  {
    id: 9,
    path: dessertByUrlPath.hotBrownie.path,
    title: 'HOT',
    title2: 'BROWNIES',
    subtitle: '15',
    video: 'https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/our-menu%2FhotBrownie.mp4?alt=media&token=a023c130-51e8-4809-9467-e88d6a6fcb41',
    image: Brownie,
    images: [
      {id: '1', src: Brownie, alt: 'Brownie'},
      {id: '3', src: Brownie2, alt: 'Brownie'},
    ],
    alt: '',
    cardHeight: {
      xs: '630px',
      sm: '800px'
    }
  },
  {
    id: 10,
    path: dessertByUrlPath.hotDrink.path,
    title: 'STICKY',
    title2: 'TOFFEE',
    title3: 'PUDDING',
    subtitle: '10',
    video: StickyToffeePudding,
    image: StickyToffeePudding,
    images: [
      {id: '2', src: StickyToffeePudding1, alt: 'Sticky Toffee Pudding'},
      {id: '3', src: StickyToffeePudding1, alt: 'Sticky Toffee Pudding'},
    ],
    alt: '',
    cardHeight: {
      xs: '690px',
      sm: '930px'
    }
  }
];

const toyImages = [
  {
    img: Toy1,
    title: 'Bed',
    author: 'swabdesign',
    path: '1'
  },
  {
    img: Toy2,
    title: 'Books',
    author: 'Pavel Nekoranec',
    path: '2'
  },
  {
    img: Toy3,
    title: 'Sink',
    author: 'Charles Deluvio',
    path: '3'
  },
  {
    img: Toy4,
    title: 'Kitchen',
    author: 'Christian Mackie',
    path: '4'
  },
  {
    img: Toy5,
    title: 'Blinds',
    author: 'Darren Richardson',
    path: '5'
  },
  {
    img: Toy6,
    title: 'Chairs',
    author: 'Taylor Simpson',
    path: '6'
  },
  {
    img: Toy7,
    title: 'Laptop',
    author: 'Ben Kolde',
    path: '7'
  },
  {
    img: Toy8,
    title: 'Doors',
    author: 'Philipp Berndt',
    path: '8'
  },
  {
    img: Toy9,
    title: 'Coffee',
    author: 'Jen P.',
    path: '9'
  },
  {
    img: Toy10,
    title: 'Storage',
    author: 'Douglas Sheppard',
    path: '10'
  },
  {
    img: Toy11,
    title: 'Candle',
    author: 'Fi Bell',
    path: '11'
  },
  {
    img: Toy12,
    title: 'Coffee table',
    author: 'Hutomo Abrianto',
    path: '12'
  }
];

export const home = {
  mainSection: {
    title: 'OUR ICE CREAM IS',
    items: [...mainSectionImages],
    image: 'Home1'
  },
  ourMenu: {
    title: 'OUR MENU',
    // subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
    items: [...dessertImages]
  },
  ourToys: {
    title: 'OUR TOYS',
    subtitle: '',
    items: [...toyImages]
  },
  aboutUs: {
    title: 'ABOUT US',
    subtitle: '',
    items: [
      {
        id: '1',
        label: 'Happy Team',
        img: Team5
      },
      {
        id: '2',
        label: 'Happy Team',
        img: Team4
      },
      {
        id: '4',
        label: 'Happy Team',
        img: Team7
      },
      {
        id: '3',
        label: 'Happy Team',
        img: Team3
      },
      {
        id: '4',
        label: 'Happy Team',
        img: Team6
      },
      {
        id: '4',
        label: 'Happy Team',
        img: Team2
      },
    ]
  }
};

export const footerData = {
  contact: {
    label: 'CONTACT',
    items: [
      { id: '1', type: 'address', label: '4 Meadow View, Kilmainham, Dublin 10, D10 KT29', href: 'https://maps.google.com/?q=4%20Meadow%20View,%20Kilmainham,%20Dublin%2010'},
      { id: '2', type: 'phone', label: '(0)16202300', href: 'tel:(0)16202300' },
      { id: '3', type: 'email', label: 'info@happydublin.ie', href: 'mailto: info@happydublin.ie' }
    ]
  },
  working: {
    label: 'WORKING',
    items: [
      { id: '1', label: 'Monday 15:00-22:00' },
      { id: '2', label: 'Tuesday 15:00-22:00' },
      { id: '3', label: 'Wednesday 15:00-22:00' },
      { id: '4', label: 'Thursday 15:00-22:00' },
      { id: '5', label: 'Friday 15:00-22:00' },
      { id: '6', label: 'Saturday 13:00-22:00' },
      { id: '7', label: 'Sunday 12:00-22:00' }
    ]
  },
  help: {
    label: 'HELP',
    items: [
      // { id: '1', label: 'Returns & Exchanges', link: '/1' },
      // { id: '2', label: 'Customer Service', link: '/2' },
      // { id: '3', label: 'Privacy Policy', link: '/3' },
      // { id: '4', label: 'Terms of Use', link: '/4' },
      // { id: '5', label: 'FAQ', link: '/5' }
    ]
  },
  getInTouch: {
    label: 'GET IN TOUCH',
    facebook: { id: '1', label: 'Facebook', link: 'https://m.facebook.com/Happyballyfermot/' },
    instagram: { id: '2', label: 'Instagram', link: 'https://www.instagram.com/happy_dublin/' },
    tikTok: { id: '3', label: 'Tik Tok', link: 'https://www.tiktok.com/@happy_dublin' }
  }
};

export const fakeImages = [
  {
    id: '1',
    alt: 'Pic 1',
    src: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
  },
  {
    id: '2',
    alt: 'Pic 2',
    src: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80'
  },
  {
    id: '3',
    alt: 'Pic 3',
    src: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
  }
];


import '../components/assets/fonts/font.css';

export const themeBase = {
  typography: {
    allVariants: {
      letterSpacing: 'normal',
      fontStyle: 'normal',
      // fontWeight: 600,
      fontFamily: 'Ubuntu-Bold',
      color: 'black'
    }
  }
};

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet';
import { OurMenu } from './OurMenu';
import { AboutUs } from './AboutUs';
import { OurToys } from './OurToys';
import { home } from '../../components/constants';
import { colors } from '../../theme';
import HappyFlowers from '../../components/assets/ourMenu/happyFlowers.jpg'

export const Home = () => {

  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.up('md'));
  const {
    mainSection,
    ourMenu,
    ourToys,
    aboutUs
  } = home;

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      sx={{ width: '100%', height: '100%' }}
    >
      <Helmet>
        <title>Home - Happy Dublin</title>
        <meta name="description" content='Happy Dublin Dessert shop serves the finest deserts and treats. We have a wide variety of deserts including Bubble waffles, crepes, shakes, ice cream, Hot brownies and more.' />
      </Helmet>
      {/* <Box> */}
      {/*   <Typography */}
      {/*    variant='h1' */}
      {/*    sx={{ */}
      {/*      position: 'absolute', */}
      {/*      fontSize: { xs: '65px', md: '95px' }, */}
      {/*      top: { xs: '40%', md: '10%', lg: '20%' }, */}
      {/*      // backgroundImage: colors.g2, */}
      {/*      // padding: '60px 0px', */}
      {/*      background: 'linear-gradient(326deg, #861657 0%, #ffa69e 74%)', */}
      {/*      WebkitBackgroundClip: 'text', */}
      {/*      WebkitTextFillColor: 'transparent', */}
      {/*      textAlign: 'center', */}
      {/*    }} */}
      {/*   > */}
      {/*    HAPPY IS LIFE. */}
      {/*   </Typography> */}
      {/* </Box> */}
      {mainSection ? (
        <Grid item xs={12}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: isTablet ? colors.pink_75 : 'transparent',
            padding: isTablet ? '60px' : '0px',
          }}>
           <Card sx={{
            width: 'auto',
            flexGrow: 1,
            padding: 0,
           }}>
             <CardCover sx={{ borderRadius: { xs: 0 } }}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
               <video
                autoPlay
                playsInline
                loop
                muted
                // controls
                // controlsList="nofullscreen"
                poster={HappyFlowers}
                style={{
                  borderRadius: isTablet ? '8px' : 0,
                }}
               >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/main-section%2FhappyMainVideo.mp4?alt=media&token=4919c23f-4349-4864-a4ab-ff803e9d4b6c"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
               </video>
            </CardCover>
            <CardContent>
              <Typography
                sx={{
                  '> .MuiTypography-root': {
                    color: 'white',
                    fontWeight: 600,
                    fontSize: 60,
                    lineHeight: 1.2,
                    textAlign: { xs: 'center',  }
                  }
              }}
                mt={{ xs: 50, sm: 60 }}
                ml={{ sm: 2 }}
              >
               <Typography>
                 GO ON
               </Typography>
                <Typography>
                  AND
                </Typography>
                <Typography>
                  BE HAPPY.
                </Typography>
              </Typography>
            </CardContent>
           </Card>
          {/* </LazyLoad> */}
          {/* <video */}
          {/*  loop */}
          {/*  autoPlay */}
          {/*  muted */}
          {/*  controls */}
          {/*  poster={ToyEx} */}
          {/*  style={{ */}
          {/*    width: isTablet ? '80%' : 'auto', */}
          {/* }} */}
          {/* > */}
          {/*  <source */}
          {/*    type="video/mp4" */}
          {/*    src='https://firebasestorage.googleapis.com/v0/b/happy-dublin-46985.appspot.com/o/main-section%2Fmain-video.mp4?alt=media&token=7b894c31-2325-4af3-a1f7-c038598521ef' */}
          {/*  /> */}
          {/*  Your browser does not support the video tag. */}
          {/* </video> */}
          { /* <FixedImage data={mainSection} /> */ }
        </Grid>
      ) : null}
      {ourMenu ? (
        <Grid
          item
          container
          xs={12}
          justifyContent='center'
          alignItems='center'
          bgcolor={colors.green_100}
        >
          <OurMenu data={ourMenu} />
        </Grid>) : null}
      {ourToys ? (
        <Grid
          item
          container
          xs={12}
          justifyContent='center'
          alignItems='center'
          sx={{ backgroundColor: 'white' }}
        >
          <OurToys data={ourToys} />
        </Grid>) : null}
      {/* <Grid */}
      {/*  item */}
      {/*  container */}
      {/*  xs={12} */}
      {/*  justifyContent='center' */}
      {/*  alignItems='center' */}
      {/* > */}
      {/*  <Review /> */}
      {/* </Grid> */}
      <Grid
        item
        container
        xs={12}
        justifyContent='center'
        alignItems='center'
      >
        <AboutUs data={aboutUs} />
      </Grid>
    </Grid>
  );
};

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const defaultTitle = 'Oops. You can\'t get there from here.';

export const Forbidden = () => {
  return (
    <Grid sx={{ height: 'calc(100vh)' }}>
      <Grid item xs={11}>
        <Typography>{defaultTitle}</Typography>
        <Button component={Link} href='/'>Continue to your Homepage</Button>
      </Grid>
    </Grid>
  );
};
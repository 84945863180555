import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
// import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';
import { colors } from '../../theme';
import { SlideZoom } from '../slide';
import { BROWSER_AGENT, getBrowserAgent } from '../../utils/getBrowserAgent';

const dessertCardSx = {
  '&.MuiCard-root': {
    width: {
      xs: '100%',
      lg: '500px'
    },
    height: {
      lg: '1030px',
    },
    display: 'flex',
    flexDirection: {
      xs: 'column',
    },
    alignItems: 'center',
    justifyContent: {
      xs: 'space-between',
      lg: 'space-between'
    },
    backgroundColor: 'antiquewhite',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    transition: '0.2s',
    borderRadius: '120px 10px 120px 10px',
    marginBottom: '40px',
    '&:hover': {
      md: {
        transform: 'scale(0.99)',
      },
      // transform: 'scale(0.99)',
      // boxShadow: '8px 8px 30px 0px rgba(42,67,113,.15)',
      // boxShadow: '0 0 10px 5px rgba(255, 255, 255, 0.4)',
      // backgroundColor: '#FBFFCB',
      // '& .MuiCardContent-root': {
        // backgroundColor: 'pink',
        // '#titleCard': {
          // color: 'white'
        // }
      // }
    },
    '& .MuiCardContent-root': {
      maxHeight: {
        xs: '420px',
        sm: '550px',
        md: '550px',
        lg: '550px'
      },
      display: 'flex',
      alignItems: 'center',
      width: {
        xs:'100%',
        lg: '510px'
      },

      flexDirection: 'column',
      padding: '20px 10px',
      backgroundColor: colors.pink_75,
      borderRadius: '100px 10px 100px 10px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      '#titleCard': {
        width: 'min-content',
        color: colors.green_100,
        marginBottom: '40px',
        textAlign: 'center'
      }
    },
    main: {
      width: {
        xs: '100%',
        lg: '100%'
      }
    },
  }
};

const shake = keyframes`
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    } `

const shakeSx = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: {
    xs: '30px',
    sm: '20px',
    md: '30px',
    lg: '40px'
  },
  '> *:nth-of-type(1)':{
    position: 'absolute',
    color: 'yellow',
    fontSize: {
      xs:'55px',
      sm:'55px',
      md: '65px',
      lg: '70px'
    },
  },
  '> *:nth-of-type(2)':{
    fontSize: {
      xs:'55px',
      sm:'55px',
      md: '65px',
      lg: '70px'
    },
    position: 'absolute',
    color: colors.chocolate,
    animation: `${shake} 1s ease-in-out infinite`
  },
};

export const DessertCard = ({ images, alt, title, title2, title3, variants, to, cardHeight }) => {

  const browserAgent = getBrowserAgent();
  const isSafari = browserAgent === BROWSER_AGENT.safari;

  return (
    <Card
      // component={MuiLink}
      // href={to}
      // underline='none'
      sx={{
        ...dessertCardSx,
        height: cardHeight
      }}
    >
      <CardContent>
        <Box>
          <Box sx={shakeSx}>
            <Typography variant='h5'>{title}</Typography>
            <Typography variant='h5'>{title}</Typography>
          </Box>
          {title2 ? (
            <Box
              sx={{
                ...shakeSx,
                marginTop: {
                  xs: isSafari ? '80px' : '50px',
                  sm: isSafari ? '75px' : '50px',
                  md: isSafari ? '90px' : '60px',
                  lg: isSafari ? '100px' : '65px'
                }
              }}
            >
              <Typography variant='h5'>{title2}</Typography>
              <Typography variant='h5'>{title2}</Typography>
            </Box>
          ): null}
          {title3 ? (
            <Box
              sx={{
                ...shakeSx,
                marginTop: {
                  xs: isSafari ? '130px' : '50px',
                  sm: isSafari ? '130px' : '50px',
                  md: isSafari ? '150px' : '60px',
                  lg: isSafari ? '160px' : '65px'
                }
              }}
            >
              <Typography variant='h5'>{title3}</Typography>
              <Typography variant='h5'>{title3}</Typography>
            </Box>
          ): null}
        </Box>
        <Box
          sx={{
            height: {
              xs: '80px',
              sm: '100px',
              md: '110px',
              lg: '110px'
            }
          }}
        />
        {variants?.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '30px',
              width: {
                xs: '100%',
                sm: '80%',
                md: '80%',
                lg: '80%'
              }
            }}
          >
            <Stack
              justifyContent='center'
              direction='row'
              flexWrap='wrap'
              useFlexGap
              spacing='10px'
            >
              {variants.map((v) =>
                <Chip
                  key={v}
                  size='medium'
                  label={v}
                  sx={{
                    fontSize: {
                      sm: '16px',
                      md: '16px',
                      lg: '20px'
                    },
                    backgroundColor: 'white',
                    color: colors.pink_75,
                    fontWeight: 600
                  }}
              />)}
            </Stack>
          </Box>) : null}
      </CardContent>
       <Box component='main'>
        <SlideZoom images={images} />
       </Box>
    </Card>
  );
};

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { dessertImages } from '../../components/constants';
import { Products } from '../../components/Product';
import { Breadcrumb } from '../../components/breadcrumb';

const titleSx = {
  fontSize: {
    xs: '45px',
    sm: '80px'
  },
  textAlign: 'center',
  color: 'white'
};
const noDessertsLabelSx = {
  fontSize: {
    xs: '20px',
    sm: '40px'
  },
  textAlign: 'center',
  color: 'white'
};
const breadcrumbItems = [
  { id: '1', label: 'Home', path: '/', isActive: true },
  { id: '2', label: 'Desserts', path: undefined, isActive: false }
];
export const Desserts = () => {

  return (
    <Box>
      <Breadcrumb items={breadcrumbItems} />
      <Grid container justifyContent='center'>
        <Grid item xs={11}>
          <Typography variant='h1' sx={titleSx}>
            Our Desserts
          </Typography>
          {dessertImages?.length ? (
            <Products images={dessertImages} urlPage='desserts' />
          ) : (
            <Typography variant='h6' sx={noDessertsLabelSx}>
              There are no desserts to display
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { drawerWidth } from '../../utils/constants';
import { colors } from '../../theme';
import HappyLogo from '../assets/happyLogoTransparent.png';

export const Drawer = ({
  container,
  isMobileOpen,
  onDrawerToggle,
  navItems,
  navItemActivated,
  onNavItemChange
}) => {

  return (
    <Box component='nav'>
      <MuiDrawer
        container={container}
        variant='temporary'
        open={isMobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: `${drawerWidth}px` }
        }}
      >
        <Box onClick={onDrawerToggle} sx={{ textAlign: 'center' }}>
          <Box>
            <img src={HappyLogo} alt='Happy Logo' width={100} height={60}/>
          </Box>
          <Divider />


          <ToggleButtonGroup
            color='primary'
            value={navItemActivated}
            exclusive
            orientation="vertical"
            onChange={onNavItemChange}
            // defaultChecked='web'
            sx={{
              '& .MuiToggleButton-root': {
                border: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }
              }
            }}
          >
            {navItems?.map((ni) => (
              <ToggleButton
                key={ni.id}
                value={ni.id}
                sx={{
                  '&.MuiToggleButton-root': {
                    color: colors.pink_75,
                    '&.Mui-selected':{
                      color: colors.pink,
                      fontWeight: 600,
                      textDecoration: 'underline'
                    }
                  }
                }}
              >
                {ni.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {/* <List> */}
          {/*  {navItems.map((item) => ( */}
          {/*    <ListItem key={item.id} disablePadding> */}
          {/*      <ListItemButton */}
          {/*        onClick={(e) => handleScrollToView(e, item.id)} */}
          {/*        sx={{ textAlign: 'center' }} */}
          {/*      > */}
          {/*        <ListItemText */}
          {/*          primary={item.label} */}
          {/*          sx={{ */}
          {/*            '& .MuiTypography-root': { */}
          {/*              color: colors.pink */}
          {/*            }} */}
          {/*          } */}
          {/*        /> */}
          {/*      </ListItemButton> */}
          {/*    </ListItem> */}
          {/*  ))} */}
          {/* </List> */}
        </Box>
      </MuiDrawer>
    </Box>
  );
};
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HomeSection } from '../../components';

export const AboutUs = ({ data }) => {
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.up('md'));
  const colNumber = isMobile ? 2 : 1
  if(!data.items.length) {
    return null
  }

  return (
    <HomeSection
      id='about-us'
      colorTitle='gray'
      data={data}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center',
        width: {
          xs: '100%',
        }, height: '100%' }}>
        <ImageList variant='masonry' cols={colNumber} gap={2}>
          {data.items.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={item.img}
                alt={item.label}
                loading='lazy'
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </HomeSection>
  );
};

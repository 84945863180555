import Stack from '@mui/material/Stack';
import { DessertCard, HomeSection } from '../../components';

export const OurMenu = ({ data }) => {

  return (
    <HomeSection
      id='our-menu'
      // viewAllHref='/desserts'
      colorButton='white'
      colorTitle='white'
      data={data}
    >
      {data.items?.length ? (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent='center'
          alignItems='center'
          flexWrap='wrap'
          gap={{ xs: 1, lg: 4 }}
          sx={{ marginBottom: 10 }}
        >
          {data.items.map((i, index) =>
            <DessertCard
              key={i.id}
              direction={(index %2) === 0 ? 'left' : 'right'}
              title={i.title}
              title2={i.title2}
              title3={i.title3}
              images={i.images}
              cardHeight={i.cardHeight}
              alt={i.alt}
              video={i.video}
              variants={i.variants}
              to={`/desserts/${i.path}`}
            />
          )}
        </Stack>) : null}
    </HomeSection>
  );
};
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AppBar } from '../appBar';
import { Drawer } from '../drawer';
import { HideOnScroll } from '../hideOnScroll';
import { ScrollTop } from '../scrollTop';
import { colors } from '../../theme';
import { navItems as navs } from '../constants';

export const handleScrollToView = (event, componentId) => {

  const anchorEl = (event.target.ownerDocument || document)
    .querySelector(`#${componentId}`);
  if (anchorEl) {
    anchorEl.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
};
export const Header = (props) => {
  const { window, children } = props;
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [navItems, setNavItems] = useState(navs);
  const container =
    window !== undefined
      ? () => window().document.body
      : undefined;

  const navigate = useNavigate();
  const location = useLocation();
  const isHomeLocation = location?.pathname === '/';
  const [navItemActivated, setNavItemActivated] = useState('app');
  const handleNavItemChange = (event, newAlignment) => {
    if (newAlignment) {
      setNavItemActivated(newAlignment);
      handleScrollOrNavigate(event, newAlignment);
    }
  };

  const handleScrollOrNavigate = (event, componentId) => {
    if (isHomeLocation) {
      handleScrollToView(event, componentId);
    } else {
      navigate(`/`);
      // handleScrollToView(event, componentId);
    }
  };

  const handleDrawerToggle = () => {
    setIsMobileOpen((prevState) => !prevState);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <HideOnScroll {...props}>
        <AppBar
          onDrawerToggle={handleDrawerToggle}
          navItems={navItems}
          navItemActivated={navItemActivated}
          onNavItemChange={handleNavItemChange}
        />
      </HideOnScroll>
      <Drawer
        container={container}
        isMobileOpen={isMobileOpen}
        onDrawerToggle={handleDrawerToggle}
        navItems={navItems}
        navItemActivated={navItemActivated}
        onNavItemChange={handleNavItemChange}
      />
      <Box
        component='main'
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Toolbar id='back-to-top-anchor' />
        {children}
      </Box>
      <ScrollTop {...props}>
        <Fab size='small' aria-label='scroll back to top'>
          <KeyboardArrowUpIcon sx={{ '&.MuiSvgIcon-root': { color: colors.pink } }} />
        </Fab>
      </ScrollTop>
    </Box>
  );
};
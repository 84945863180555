export const BROWSER_AGENT = {
  chrome: 'CHROME',
  safari: 'SAFARI',
  edge: 'EDGE',
  firefox: 'FIREFOX',
  opera: 'OPERA'
}
function test(regexp) {
  return regexp.test(window.navigator.userAgent)
}

export function getBrowserAgent() {
  switch (true) {
    case test(/edg/i): return BROWSER_AGENT.edge;
    // case test(/trident/i): return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i): return BROWSER_AGENT.firefox;
    case test(/opr\//i): return BROWSER_AGENT.opera;
    case test(/chrome|chromium|crios/i): return BROWSER_AGENT.chrome;
    case test(/safari/i): return BROWSER_AGENT.safari;
    default: return "Other";
  }
}
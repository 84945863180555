import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Box from '@mui/material/Box';
import './slide.css';

export const SlideZoom = ({ images }) => {

  return (
    <Zoom scale={1.4} duration={2500}>
      {images.map((i) => (
        <Box
          key={i.id}
          component='div'
          sx={{
            width: '100%',
            height: {
              xs: '400px',
              sm: '500px',
              md: '500px',
              lg: '570px'
            }
          }}>
            <img
              src={i.src}
              alt={i.alt}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%'
              }}
            />
        </Box>
      ))}
    </Zoom>
  );
};
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export const Breadcrumb = ({ items }) => {
  return (
    <Box sx={{ margin: '10px 0px 0px 10px' }}>
      <Breadcrumbs aria-label='breadcrumb' separator='>'>
        {items.map((i) => (
          i.isActive ? (
            <Link
              key={i.id}
              underline='hover'
              href={i.path}
            >
              {i.label}
            </Link>
          ) : (
            <Typography
              key={i.id}
              color='text.primary'
            >
              {i.label}
            </Typography>
          )
        ))}
      </Breadcrumbs>
    </Box>
  );
};

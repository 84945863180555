import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Link from '@mui/material/Link';
import ImageListItemBar from '@mui/material/ImageListItemBar';

export function Products({ images, urlPage }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const cols = matches ? 3 : 2;
  const spacing = matches ? 4 : 0;
  return (
    <Box sx={{ width: '100%' }}>
      <ImageList cols={cols} spacing={spacing}>
        {images.map((i) => (
          <ImageListItem
            key={i.id}
            component={Link}
            href={`/${urlPage}/${i.path}`}
            underline='none'>
            <img
              src={i.image}
              alt={i.title}
              loading='lazy'
            />
            <ImageListItemBar
              title={i.title}
              subtitle={<span>{i.subtitle} &#8364;</span>}
              position='below'
              sx={{ background: 'transparent' }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

import { Routes as RouterRoutes, Route } from 'react-router-dom';
import {
  Home,
  Desserts,
  DessertDetail,
  Toys,
  ToyDetail,
  Forbidden
} from '../pages';

export const Routes = () => {
  return (
    <RouterRoutes>
      <Route path='/' element={<Home />} />
      <Route path='desserts'>
        <Route index path='' element={<Desserts />} />
        <Route path=':dessertId' element={<DessertDetail />} />
      </Route>
      <Route path='toys'>
        <Route index path='' element={<Toys />} />
        <Route path=':toyId' element={<ToyDetail />} />
      </Route>
      <Route path='*' element={<Forbidden />} />
      <Route path='forbidden' element={<Forbidden />} />
    </RouterRoutes>
  );
};
import { CssBaseline } from '@mui/material';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/material/Box';
import { Routes } from './routes';
import { themeBase } from './theme';
import { Footer, Header } from './components';

const materialTheme = materialExtendTheme({...themeBase});

const App = () => {

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <Box id='app'>
          <CssBaseline />
            <Header>
              <Routes />
              <Footer />
            </Header>
        </Box>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default App;

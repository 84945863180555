import { useNavigate } from 'react-router';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import { colors } from '../../theme';
import { headerHeight } from '../../utils/constants';
import HappyLogo from '../assets/happyLogoTransparent.png'

export const AppBar = ({
  onDrawerToggle,
  navItems,
  onNavItemChange,
  navItemActivated
}) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(`/`);
  }

  return (
    <MuiAppBar component='nav' sx={{
      '&.MuiAppBar-root': { backgroundColor: 'white', height: `${headerHeight}px` }
    }}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={onDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon
            sx={{
              '&.MuiSvgIcon-root': {
                color: colors.pink
              }
            }}
          />
        </IconButton>
        <Box
          sx={{
            ml: '85px',
            mt: '5px',
            display: { sm: 'none' },
            color: colors.pink,
        }}
        >
          <Button onClick={handleLogoClick}>
            <img
              src={HappyLogo}
              alt='Happy Logo'
              width={100}
              height={60}
            />
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, }}>
          <Button onClick={handleLogoClick}>
            <img
              src={HappyLogo}
              alt='Happy Logo'
              width={100}
              height={60}
              style={{
                marginTop: '10px',
              }}
            />
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ToggleButtonGroup
            color='primary'
            value={navItemActivated}
            exclusive
            onChange={onNavItemChange}
            // defaultChecked='web'
            sx={{
              '& .MuiToggleButton-root': {
                border: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }
              }
            }}
          >
            {navItems?.map((ni) => (
              <ToggleButton
                key={ni.id}
                value={ni.id}
                sx={{
                  '&.MuiToggleButton-root': {
                    color: colors.pink_75,
                    '&.Mui-selected':{
                      color: colors.pink,
                      fontWeight: 700,
                      textDecoration: 'underline'
                    }
                  }
                }}
              >
                {ni.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {/* {navItems?.map((item) => ( */}
          {/*  <Button */}
          {/*    key={item.id} */}
          {/*    onClick={(e) => handleScrollToView(e, item.id)} */}
          {/*    sx={{ color: colors.pink }} */}
          {/*  > */}
          {/*    {item.label} */}
          {/*  </Button> */}
          {/* ))} */}
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';
import { colors } from '../../theme';
import { HomeSection } from '../../components';
// component={Link} href={`/toys/${item.path}`}
export const OurToys = ({ data }) => {

  return (
    <HomeSection
      id='our-toys'
      // viewAllHref='/toys'
      colorButton={colors.pink}
      colorTitle={colors.gray}
      data={data}
    >
      {data.items?.length ? (
        <ToyImages items={data.items} />
      ) : null}
    </HomeSection>
  );
};


function ToyImages({ items }) {
  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'));
  const cols = matches ? 6 : 2;
  const gap = matches ? 8 : 2;
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ImageList variant='masonry' cols={cols} gap={gap}>
        {items.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={item.img}
              srcSet={item.img}
              alt=''
              loading='lazy'
            />
            <ImageListItemBar
              title=''
              position='top'
              sx={{
                '& .MuiImageListItemBar-titleWrap': {
                  color: 'white',
                  fontWeight: 600
                },
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
import Box from '@mui/material/Box';

export const ToyDetail = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh)'

      }}
    >
      <p>Toy Detail</p>
    </Box>
  );
};

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { footerData } from '../constants';
import { Facebook, Instagram, Tiktok } from '../assets/svg';
import { colors } from '../../theme';

const GridContainer = ({ children, ...other }) => {
  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      sx={{
        height: '100%'
      }}
    >
      <Grid item xs={11} {...other}>
        {children}
      </Grid>
    </Grid>
  );
};

const Sections = ({ items, component }) => {
  return (
    <List disablePadding>
      {items.map((i) => (
        <ListItem
          key={i.id}
          disablePadding
          component={component}
          underline='hover'
          href={i.link}
          sx={{ marginBottom: '5px' }}
        >
          <ListItemText
            primary={i.label}
            primaryTypographyProps={{
              variant: 'body2',
              fontFamily: 'Ubuntu-Medium',
              textAlign: { xs: 'center', sm: 'center' },
              color: 'white',
              fontSize: {
                xs: '20px',
                sm: '20px',
                md: '24px',
                lg: '24px'
              },
            }}
            sx={{
              marginTop: 0,
              marginBottom: 0
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};
const itemSectionLabel = {
  textAlign: { xs: 'center', sm: 'center' },
  fontSize: {
    xs: '30px',
    lg: '40px'
  },
  marginBottom: '10px',
  color: 'white'
};

const itemSection = {
  fontFamily: 'Ubuntu-Medium',
  fontSize: {
    xs: '20px',
    sm: '20px',
    md: '24px',
    lg: '24px'
  },
  marginBottom: '20px',
  textAlign: { xs: 'center', sm: 'center' },
  color: 'white'
};

const socialMediaIconSx = {
  marginRight: '10px',
  '> *:nth-of-type(1)': {
    color: colors.pink,
    width: {
      xs: '50px',
      lg: '65px'
    },
    height: {
      xs: '50px',
      lg: '65px'
    }
  }
}
export const Footer = () => {
  const {
    contact,
    working,
    help,
    getInTouch
  } = footerData;

  return (
    <Box
      id='footer'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'pink',
        height: {
          xs: '820px',
          sm: '600px',
          md: '680px',
          lg: '720px',
        },
        padding: {
          // xs: '40px 0px 210px 0px'
        }
      }}
    >
      <GridContainer
        container
        flexDirection='row'
        justifyContent='space-between'
        alignContent='center'
        columnSpacing='10px'
        rowSpacing='30px'
        sx={{
          height: '100%',
          // minHeight: '500px'
          // paddingTop: '150px'
        }}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' sx={itemSectionLabel}>{contact.label}</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            {contact?.items?.map((i) => (
              <Typography
                component={Link}
                key={i.id}
                underline='none'
                target={i.type === 'address' ? '_blank' : undefined}
                variant='body2'
                href={i.href}
                sx={itemSection}
              >
                {i.label}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' sx={itemSectionLabel}>{working.label}</Typography>
          <Sections items={working.items} />
        </Grid>
        {help?.items?.length ? (
          <Grid item xs={12} sm={4}>
            <Typography variant='h6' sx={itemSectionLabel}>{help.label}</Typography>
            <Sections items={help.items} component={Link} />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' sx={itemSectionLabel}>{getInTouch.label}</Typography>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'center' } }}>
            <Link href={getInTouch.facebook.link} target='_blank' sx={socialMediaIconSx}>
              <Facebook />
            </Link>
            <Link href={getInTouch.tikTok.link} target='_blank' sx={socialMediaIconSx}>
              <Tiktok />
            </Link>
            <Link href={getInTouch.instagram.link} target='_blank' sx={socialMediaIconSx}>
              <Instagram />
            </Link>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
};
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { SlideZoom } from '../../components';
import { dessertByUrlPath, fakeImages } from '../../components/constants';
import { Breadcrumb } from '../../components/breadcrumb';

const breadcrumbItems = [
  { id: '1', label: 'Home', path: '/', isActive: true },
  { id: '2', label: 'Desserts', path: '/desserts', isActive: true },
  { id: '3', label: 'Dessert detail', path: undefined, isActive: false }
];

export const DessertDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { dessertId } = params;
  const dessertSelected = Object.values(dessertByUrlPath)
    .find((d) => d.path === dessertId);

  useEffect(() => {
    if (!dessertSelected) {
      navigate('/desserts', { replace: true });
    }
  }, [navigate, dessertSelected]);


  return (
    <Box>
      <Breadcrumb items={breadcrumbItems} />
      <Grid
        container
        justifyContent='center'
      >
        <Grid item xs={11}>
          <Typography variant='h1' sx={{ textAlign: 'center', color: 'white' }}>{dessertSelected?.title}</Typography>
          <Box
            sx={{
              height: '600px',
              '> *:nth-of-type(1)': {
                display: 'flex',
                justifyContent: 'center'
              }
            }}>
            <SlideZoom images={fakeImages} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
